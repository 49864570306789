import {createMuiTheme} from "@material-ui/core";

const WHITE = '#fff';

const paletteConfig = {
    palette: {
        primary: {
            main: '#00AED9',
        }
    }
};

const GLOBAL_THEME = createMuiTheme(paletteConfig);

export default GLOBAL_THEME;