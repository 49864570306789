import {HttpResponse} from "../model/HttpResponse";

export abstract class ResponseReader {

    public abstract supports(resp: Response): boolean;

    public abstract read(resp: Response): Promise<HttpResponse>

    public abstract next(): ResponseReader | null;

    protected assignResponseData = <T = any>(data: T, response: Response): HttpResponse<T> => {
        return Object.assign(response, {
            data: data
        });
    }

}

