import React, {Component} from "react";
import {PaymentInfo} from "src/modules/flow/payment/model/PaymentInfo";
import {PlaidMetadata} from "src/modules/plaid/module/PlaidMetadata";
import {PrePaymentAchSubmitButton} from "./submit/PrePaymentAchSubmitButton";
import {PrePaymentFeeAch} from "src/modules/flow/payment/component/pre-payment/pre-payment-fee-ach/PrePaymentFeeAch";
import {PrePaymentAchPlaidLink} from "./link/PrePaymentAchPlaidLink";
import {PrePaymentAccountPicker} from "./picker/PrePaymentAccountPicker";
import {PrePaymentAgreementAch} from "../../pre-payment-agreement/PrePaymentAgreementAch";

interface Props {
    paymentInfo: PaymentInfo,
    fee: string,
    disabled: boolean,
}

interface Validity {
    form: boolean;
    link: boolean;
}

interface State {
    publicToken?: string;
    pickedAccountId?: string;
    metadata?: PlaidMetadata;
    validity: Validity & {
        submitButtonDisabled: boolean;
    };
}

export class PrePaymentAch extends Component<Props, State> {

    private readonly form = React.createRef<HTMLFormElement>()

    constructor(p: Props) {
        super(p);
        this.state = {
            validity: {
                form: false,
                link: false,
                submitButtonDisabled: true
            }
        };
    }


    private handleFormChange = () => {
        let validity = this.form.current?.checkValidity();
        this.updateValidity({
            form: validity
        });
    };

    private handlePlaidUpdate = (publicToken: string, metadata: PlaidMetadata) => {
        this.setState({
            publicToken: publicToken,
            metadata: metadata,
            pickedAccountId: metadata?.accounts[0]?.id
        }, () => this.updatePlaidValidity());
    };

    private handleAccountPick = (accountId: string) => {
        this.setState({
            pickedAccountId: accountId
        }, () => this.updatePlaidValidity())
    }

    private updatePlaidValidity = () => {
        let validity = this.state.publicToken != null && this.state.pickedAccountId != null;
        this.updateValidity({
            link: validity
        })
    };

    private updateValidity = (update: { form?: boolean, link?: boolean, }) => {
        let validity = Object.assign(this.state.validity, update);
        //iterate over all validation objects, see if all are 'true'
        let allValid = this.validateAll(validity);
        validity.submitButtonDisabled = !allValid;
        this.setState({
            validity: validity
        });

    };

    private validateAll = (validity: Validity) => {
        let allValid = true;
        for (let [k, v] of Object.entries(validity)) {
            if (k !== 'submitButtonDisabled') {
                allValid = allValid && v as boolean;
            }
        }
        return allValid;
    };


    render() {
        return <>
            <form ref={this.form} onChange={this.handleFormChange}>
                <fieldset disabled={this.props.disabled}>
                    <PrePaymentFeeAch fee={this.props.fee}/>

                    <PrePaymentAchPlaidLink options={{
                        token: this.props.paymentInfo.plaidLinkToken as string,
                        // @ts-ignore
                        onSuccess: this.handlePlaidUpdate,
                        env: process.env.REACT_APP_PLAID_ENVIRONMENT
                    }}/>
                    <PrePaymentAccountPicker metadata={this.state.metadata}
                                             onPickedAccount={this.handleAccountPick}
                                             pickedAccount={this.state.pickedAccountId}
                    />
                    <PrePaymentAgreementAch/>
                    <PrePaymentAchSubmitButton submitButtonDisabled={this.state.validity.submitButtonDisabled}
                                               publicToken={this.state.publicToken}
                                               pickedAccountId={this.state.pickedAccountId}
                    />
                </fieldset>
            </form>
        </>
    }
}

