import React, {KeyboardEvent} from "react";
import {ValueEvent} from "../../../common/model/ValueEvent";
import  "./LoanNumberPrompt.scss";
import {ThemeProvider} from "@material-ui/core/styles";
import {TextField} from "@material-ui/core";
import {container} from "tsyringe";
import {BrowserService} from "../../../common/browser/BrowserService";
import {LoanNumberPromptThemes} from "../theme/LoanNumberPromptThemes";
import {LoanNumberPromptTooltip} from "./tooltip/LoanNumberPromptTooltip";
import {SubmitButton} from "../../../input/component/submit_button/SubmitButton";
import {BrowserServiceSingleton} from "src/Context";

interface Props {
    onInputUpdate: (event: ValueEvent) => void,
    error: boolean
}

interface State {
    loanNumber?: string;
    disableForwardButton: boolean;
}

export class LoanNumberPromptInput extends React.Component<Props, State> {

    private readonly TEXT_INPUT_THEME = LoanNumberPromptThemes.TEXT_INPUT_THEME;
    private readonly ENTER_KEY = "Enter";
    private browserService = BrowserServiceSingleton;


    constructor(props: Props) {
        super(props);
        this.state = {
            disableForwardButton: true
        }
    }

    private handleChange = (event: ValueEvent) => {
        this.setState({
            loanNumber: event.target.value,
            disableForwardButton: event.target.value.length === 0,
        });
        this.props.onInputUpdate(event);
    }

    private handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === this.ENTER_KEY) {
            this.handleProceedButtonClick();
        }
    };


    private handleProceedButtonClick = () => {
        let url = `/loan/${this.state.loanNumber}`;
        this.browserService.removeUrlParams();
        this.browserService.redirectTo(url);
    }

    render() {
        return (
            <>
                <div id="payment-form">
                    <form>
                        <div className="input-wrapper">
                            <ThemeProvider theme={this.TEXT_INPUT_THEME}>
                                <TextField className="input_text_field"
                                           label="Your Loan Number"
                                           required
                                           onChange={this.handleChange}
                                           onKeyDown={this.handleKeyDown}
                                           error={this.props.error}/>
                            </ThemeProvider>
                            <LoanNumberPromptTooltip/>
                        </div>

                    </form>
                    <div className="input-wrapper">
                        <SubmitButton disabled={this.state.disableForwardButton}
                                      onClick={this.handleProceedButtonClick}>
                            Start Payment
                        </SubmitButton>
                    </div>
                </div>
            </>
        );
    }
}