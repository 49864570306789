import {PlaidLinkOptions, usePlaidLink} from "react-plaid-link";
import React from "react";
import  './PrePaymentAchPlaidLink.tsx.scss'
import './../../../PrePayment.scss';

export function PrePaymentAchPlaidLink(props: { options: PlaidLinkOptions }) {
    let link = usePlaidLink(props.options);
    let onClick = (event: MouseEvent & any) => {
        event.preventDefault();
        link.open();
    }
    return (
        <div id={'plaid_button_wrapper'} className={'field'}>
            <button type={'button'} className={'plaid_button'} onClick={onClick}>
                Link your bank account
            </button>
        </div>
    );
}