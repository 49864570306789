import React from "react";
import {TextField} from "@material-ui/core";
import  "../../PrePayment.scss";

interface Props {
    suggestedName: string,

}

export class PrePaymentNameOnCard extends React.Component<Props> {

    render() {
        return (
            <div className="field">
                <TextField label={'Name:'}
                           fullWidth={true}
                           placeholder={"Name on credit card"}
                           name={"nameOnCard"}
                           required={true}
                           defaultValue={this.props.suggestedName}>
                </TextField>

            </div>
        );
    }

}