import {HttpResponse} from "../../model/HttpResponse";
import {ResponseReader} from "../ResponseReader";
import {HeaderResponseReader} from "../HeaderResponseReader";
import {NullResponseReader} from "./NullResponseReader";


export class TextResponseReader extends HeaderResponseReader {

    constructor(private nextReader: NullResponseReader) {
        super();
    }

    public read(resp: Response): Promise<HttpResponse> {
        return resp.text()
            .then(text => this.assignResponseData(text, resp));
    }

    next(): ResponseReader {
        return this.nextReader;
    }

    protected contentType(): string {
        return "text/plain";
    }
}