import {HttpService} from "../../common/http/service/HttpService";
import {
    CreateTokenCardData,
    loadStripe,
    Stripe,
    StripeCardElement,
    StripeElements,
    StripeError,
    Token
} from '@stripe/stripe-js';
import {CreatePaymentTokenOptions} from "../model/CreatePaymentTokenOptions";
import {PromiseUtil} from "../../util/PromiseUtil";
import {PaymentConfirmation} from "../model/PaymentConfirmation";
import {EventTypeHolder, EventUtil} from "../../util/EventUtil";

export class StripeService {

    private stripe!: Stripe;
    private elements!: StripeElements;
    private readonly stripeLoadedEvent = EventTypeHolder.stripeLoaded;
    private readonly elementsLoadedEvent = EventTypeHolder.stripeElementsLoaded;

    constructor(private httpService: HttpService) {
        EventUtil.onEvent(this.stripeLoadedEvent, e => console.log('stripe loaded!'));
    }

    public initStripe = (publicKey: string): Promise<Stripe> => {
        return this.loadStripe(publicKey)
            .then(stripe => {
                this.stripe = stripe;
                // @ts-ignore
                this.elements = stripe.elements();
                EventUtil.fireEvent(this.stripeLoadedEvent, this.stripe);
                EventUtil.fireEvent(this.elementsLoadedEvent, this.elements);
                return stripe;
            })
    }

    public elementsPromise = (): Promise<StripeElements> => {
        if (this.elements) {
            return PromiseUtil.of(this.elements);
        } else {
            return PromiseUtil.onEvent<StripeElements>(this.elementsLoadedEvent);
        }
    }

    public stripePromise = (): Promise<Stripe> => {
        if (this.stripe != null) {
            return PromiseUtil.of(this.stripe);
        } else {
            return PromiseUtil.onEvent<Stripe>(this.stripeLoadedEvent);
        }
    }

    private loadStripe = (publicKey: string): Promise<Stripe> => {
        if (this.stripe != null) {
            return PromiseUtil.of(this.stripe);
        } else {
            console.log('loading stripe...');
            return loadStripe(publicKey) as Promise<Stripe>;
        }
    };

    public fetchPaymentIntentSecret = (options: CreatePaymentTokenOptions): Promise<string> => {
        return this.httpService.fetchString({
            url: '/stripe/process_payment/credit_card',
            method: 'POST',
            data: options
        });
    }

    public confirmCardPayment = (card: StripeCardElement, options: CreatePaymentTokenOptions): Promise<PaymentConfirmation> => {
        return this.fetchPaymentIntentSecret(options)
            .then(secret => this.doConfirmCardPayment(card, options, secret));
    }

    private doConfirmCardPayment = (card: StripeCardElement,
                                    options: CreatePaymentTokenOptions,
                                    secret: string): Promise<PaymentConfirmation> => {
        return this.stripe.createToken(card, this.getTokenData(options))
            .then(this.handleStripeToken)
            .then(token => this.doConfirmTokenizedPayment(secret, token))
            .catch(err => {
                console.log(err);
                throw err;
            });
    }

    private doConfirmTokenizedPayment = (secret: string, token: Token) => {
        let data = this.createPaymentData(token);
        return this.stripe.confirmCardPayment(secret, data);
    }

    private createPaymentData = (token: Token) => {
        return {
            payment_method: {
                card: {
                    token: token.id
                }
            }
        };
    }


    private getTokenData = (options: CreatePaymentTokenOptions): CreateTokenCardData => {
        return {
            name: options.name,
        };
    }

    private handleStripeToken = (result: { token?: Token; error?: StripeError }) => {
        if (result.error != null) {
            return Promise.reject(result.error.message);
        } else {
            return result.token as Token;
        }
    };

}
