import {getAbsoluteUrl} from "../../../util/UrlUtil";
import {SnackbarService} from "../../../snackbar/service/SnackbarService";
import {BrowserService} from "../../browser/BrowserService";
import {HttpRequest} from "../model/HttpRequest";
import {HttpResponse} from "../model/HttpResponse";
import {BadResponseBody} from "../model/BadResponseBody";
import {JsonResponseReader} from "./impl/JsonResponseReader";
import {ResponseReader} from "./ResponseReader";
import {HttpError} from "../model/HttpError";
import {RedirectErrorParams} from "../../../flow/error/model/RedirectErrorParams";


export class HttpService {

    constructor(private browserService: BrowserService,
                private responseReader: JsonResponseReader) {
    }

    public fetchString = (config: HttpRequest): Promise<string> => {
        return this.fetch(config)
            .then(resp => resp.data as string);
    }

    public fetchAs = <T>(config: HttpRequest): Promise<T> => {
        return this.fetch(config)
            .then(resp => resp.data as T);
    };

    public fetch = (config: HttpRequest): Promise<HttpResponse> => {
        let request = this.prepareRequest(config);
        let absoluteUrl = getAbsoluteUrl(config.url);
        return fetch(absoluteUrl, request)
            .then(this.readResponseData)
            .then(this.handleResponse);
    }

    private readResponseData = (resp: Response): Promise<HttpResponse> => {
        let reader = this.responseReader as ResponseReader | null;
        while (reader != null) {
            if (reader.supports(resp)) {
                return reader.read(resp);
            } else {
                reader = reader.next();
            }
        }
        return Promise.reject('No reader is suitable for such content type');
    }

    private prepareRequest = (config: HttpRequest<any>) => {
        let request = Object.assign(config, this.defaultRequest);
        this.setRequestData(request);
        return request;
    }

    private setRequestData = (request: HttpRequest<any> & RequestInit) => {
        if (request.data) {
            request.body = JSON.stringify(request.data);
        }
    }

    private defaultRequest: RequestInit = {
        credentials: "include",
        headers: {
            'Content-type': 'application/json',
        }
    }

    private handleResponse = (resp: HttpResponse): HttpResponse => {
        if (this.isRedirect(resp)) {
            this.handleRedirect(resp);
        }
        if (!this.is2xxSuccessful(resp)) {
            this.handleGenericBadResponse(resp);
        }
        return resp;
    }

    private handleRedirect(resp: HttpResponse<any>) {
        let search = new URL(resp.url).searchParams;
        let params = this.browserService.readUrlParamsAs<RedirectErrorParams>(search);
        this.browserService.redirectOnParams(params);
        // error thrown to stop following asynch tasks and promises from .setState({...}) memory leaks
        throw new Error("Promise aborted due to redirect");
    }


    private handleGenericBadResponse(resp: HttpResponse<any>) {
        let response = resp.data as BadResponseBody;
        let errorMessage = SnackbarService.DEFAULT_ERROR_MESSAGE;
        if (response && response.message != null && response.message.length) {
            errorMessage = response.message;
        } else if (response && response.error != null && response.error.length) {
            errorMessage = response.error;
        }
        throw new HttpError(resp.status, errorMessage);
    }

    private is2xxSuccessful = (response: Response): boolean => {
        return Math.floor(response.status / 100) === 2;
    }

    private isRedirect = (response: Response): boolean => {
        return response.redirected;
    }


}

