import React from "react";
import "../../../../../../app/tileable/instructions/component/Instructions.scss";
import {CallForHelpLink} from "../../../../../../app/tileable/instructions/contats/CallForHelpLink";
import {EmailForHelpLink} from "../../../../../../app/tileable/instructions/contats/EmailForHelpLink";
import {LoanData} from "../../../../../loan-details/model/LoanData";

interface Props {
    loan: LoanData;
    address: string;
}

export class PrePayAchInstructions extends React.Component<Props> {
    render() {
        return <>
            <h1>Make your <br/>
                <span className="white-text">First Payment.</span>
            </h1>

            <p>
                This payment will cover the <strong>First Month's Payment </strong> for your mortgage
                for the property at <strong>{this.props.address}</strong>.
            </p>

            <p>Have questions? You can always <CallForHelpLink support={this.props.loan.support}>call</CallForHelpLink>
                &nbsp;or&nbsp;
                <EmailForHelpLink support={this.props.loan.support}>email</EmailForHelpLink> our support team for help!
            </p>
        </>;
    }
}