import React from "react";
import  "../PostPayment.scss";
import {PaymentResult} from "../../../../../stripe/model/PaymentResult";
import {OptionalTableCell} from "../../../../shared/component/OptionalTableCell";
import {PostPaymentItem} from "../item/PostPaymentItem";

interface Props {
    result: PaymentResult,
}

export class PostPaymentTable extends React.Component<Props> {

    render() {
        let result = this.props.result;
        return (
            <PostPaymentItem>
                <table id="payment-summary-table">
                    <thead>
                    <tr>
                        <th>Payment Summary:</th>
                    </tr>
                    </thead>

                    <tbody>
                    <OptionalTableCell title={'Fee:'}>
                        {result.fee}
                    </OptionalTableCell>
                    <OptionalTableCell title={'Payment Amount:'}>
                        {result.paymentAmount}
                    </OptionalTableCell>
                    <OptionalTableCell title={'Paid for:'}>
                        {result.paidFor}
                    </OptionalTableCell>
                    <OptionalTableCell title={'Paid on:'}>
                        {result.paidOn}
                    </OptionalTableCell>
                    <OptionalTableCell title={'Payment Method'}>
                        {this.getPaymentMethod()}
                    </OptionalTableCell>
                    </tbody>
                </table>
            </PostPaymentItem>
        );
    }

    private getPaymentMethod = (): string | null => {
        let creditCardLast4Digits = this.props.result.creditCardLast4Digits;
        if (creditCardLast4Digits != null) {
            return `Credit Card ending in ${creditCardLast4Digits}`;
        }
        let achBankAccountLast4Digits = this.props.result.achBankAccountLast4Digits;
        if (achBankAccountLast4Digits != null) {
            return `ACH from checking account ending in ${achBankAccountLast4Digits}`;
        } else {
            return null;
        }
    };
}