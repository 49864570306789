import {CustomEventListener, EventUtil} from "../../util/EventUtil";

interface EventDescriptor {
    name: string,
    callback: EventListener | CustomEventListener<any>
}

export class EventRegistry {

    private descriptors: EventDescriptor[] = [];

    push(descriptor: EventDescriptor) {
        this.descriptors.push(descriptor);
        EventUtil.onEvent(descriptor.name, descriptor.callback as EventListener);
    }

    pushAll(descriptors: EventDescriptor[]) {
        descriptors.forEach(d => this.push(d));
    }

    clear() {
        this.descriptors.forEach(descriptor => {
            EventUtil.remove(descriptor.name, descriptor.callback);
        })
    }

}