import React, {Component} from "react";
import {OptionalTableCell} from "../../../shared/component/OptionalTableCell";

interface Props {
    firstName: string;
}

export class LoanDetailsTableFirstName extends Component<Props> {

    render() {
        return <OptionalTableCell title={'First Name'}>
            {this.props.firstName}
        </OptionalTableCell>;
    }
}