import React from "react";
import './Instructions.scss'
import   '../../../../../App.scss'
import {BrandLegalInfo} from "../../footer/component/BrandLegalInfo";


export class Instructions extends React.Component {

    render() {
        return <>
            <div id="payment-instructions" className="payment-page-section">
                <div className="content-inner-wrapper">
                    {this.props.children}
                </div>
                <BrandLegalInfo/>
            </div>
        </>;
    }

}