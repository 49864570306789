import {ThemeUtil} from "../../../../../util/ThemeUtil";

export class PrePaymentThemeHolder {
    public static readonly THEME = ThemeUtil.usingGlobalTheme({
        overrides: {
            MuiInput: {
                underline: {
                    '&$disabled:before': {
                        'border-bottom-style': 'solid'
                    }
                }
            }
        }
    })
}