import React from "react";
import  "./../PrePayment.scss";
import Tooltip from "@material-ui/core/Tooltip";

export class PrePaymentFeeTooltipAch extends React.Component {

    render() {
        return <div className="tool-tip">
            <Tooltip title={this.getTitle()}>
                <div className="tip-button">
                    ?
                </div>
            </Tooltip>
        </div>;
    }

    private getTitle = () => <p>
        Your monthly mortgage payment made up of principal and interest .
        Some payments also include real estate or property taxes.
        Please consult your closing document for a detailed breakdown.
    </p>;
}