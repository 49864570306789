import React from "react";
import  "../LoanDetailsTable.scss";
import {LoanDetailsTableThread} from "../LoanDetailsTableThread";

export class LoanDetailsTableBody extends React.Component {

    render() {
        return <table id="loan-review-table">
            <LoanDetailsTableThread/>

            <tbody>
            {this.props.children}
            </tbody>

        </table>
    };


}