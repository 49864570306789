import React from "react";
import  "./LoanNumberPrompt.scss";
import {ThemeProvider} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {LoanNumberPromptThemes} from "../theme/LoanNumberPromptThemes";
import {LoanDetailsCaption} from "../../loan-details-caption/component/LoanDetailsCaption";
import {LoanType} from "../../loan-details/model/LoanType";

interface Props {
    errorMessage?: string;
    email?: string;
    phone?: string;
    type?: LoanType;
}

export class LoanNumberPromptError extends React.Component<Props> {

    private readonly ERROR_MESSAGE_THEME = LoanNumberPromptThemes.ERROR_MESSAGE_THEME;

    render() {
        return <>
            <div id={'error_wrapper'} className="error_wrapper">
                <ThemeProvider theme={this.ERROR_MESSAGE_THEME}>
                    <Typography color={"error"}>
                        <label className="error_message">{this.props.errorMessage}</label>
                    </Typography>
                </ThemeProvider>
                {this.getContactInfoComponent()}
            </div>
        </>
    }

    private getContactInfoComponent() {
        if (this.props.email != null &&
            this.props.phone != null &&
            this.props.type != null) {
            return <>
                <label className="error_message">
                    <LoanDetailsCaption
                        type={this.props.type}
                        support={{
                            workingPhone: this.props.phone,
                            email: this.props.email
                        }}>
                    </LoanDetailsCaption>
                </label>
            </>;
        } else {
            return <></>
        }
    }
}