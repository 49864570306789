import GLOBAL_THEME from "../../../GlobalCssTheme";
import {ThemeUtil} from "../../util/ThemeUtil";

export class SnackbarThemeHolder {
    public static readonly RED_THEME = ThemeUtil.usingGlobalTheme({
        overrides: {
            MuiSnackbarContent: {
                root: {
                    backgroundColor: GLOBAL_THEME.palette.error.main
                }
            }
        }
    })

    public static readonly GREEN_THEME = ThemeUtil.usingGlobalTheme({
        overrides: {
            MuiSnackbarContent: {
                root: {
                    backgroundColor: GLOBAL_THEME.palette.success.main
                }
            }
        }
    })

    public static readonly GREY_THEME = ThemeUtil.usingGlobalTheme();
}