import {HttpService} from "../../../common/http/service/HttpService";
import {LoanData} from "../model/LoanData";
import {SessionService} from "../../../common/session/SessionService";

export class LoanDetailsService {

    constructor(private httpService: HttpService,
                private sessionService: SessionService) {

    }

    public loginByLoanNumber = (loanNumber: string): Promise<any> => {
        return this.httpService.fetch({
            url: `loan-number/${loanNumber}`,
            method: 'GET'
        });
    };


    public fetchLoanDetail = (): Promise<LoanData> => {
        return this.httpService.fetchAs<LoanData>({
            url: 'loan-details/',
            method: 'GET'
        }).then(loanData => {
            this.sessionService.removePaymentInfo();
            return this.sessionService.storeLoan(loanData);
        })
    };

}
