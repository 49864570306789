import React from "react";
import {Route, Router, Switch} from "react-router-dom";
import {LoanNumberPrompt} from "../../../flow/loan_number_prompt/component/LoanNumberPrompt";
import {LoanDetails} from "../../../flow/loan-details/component/LoanDetails";
import {Payment} from "../../../flow/Payment";
import {ErrorPage} from "../../../flow/error/component/ErrorPage";
import {BrowserServiceSingleton} from "src/Context";

export class AppRouter extends React.Component {

    private readonly browserService = BrowserServiceSingleton;

    render() {
        return (
            <Router history={this.browserService.history}>
                <Switch>
                    <Route path='/loan/:loanNumber' component={LoanDetails}/>
                    <Route path='/payment' component={Payment}/>
                    <Route path='/error' component={ErrorPage}/>
                    <Route exact path='/**' component={LoanNumberPrompt}/>
                </Switch>
            </Router>
        );
    }

}