import React, {Component} from "react";

interface Props {
    title: string;
}

export class OptionalTableCell extends Component<Props> {

    render() {
        if (this.props.children) {
            return <tr>
                <td>
                    {this.props.title}
                </td>
                <td>
                    {this.props.children}
                </td>
            </tr>;
        } else {
            return <></>;
        }

    }

}