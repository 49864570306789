import {BrowserService,} from "src/modules/common/browser/BrowserService";
import {JsonResponseReader} from "src/modules/common/http/service/impl/JsonResponseReader";
import {HttpService} from "src/modules/common/http/service/HttpService";
import {PaymentService} from "src/modules/flow/payment/service/PaymentService";
import {SessionService} from "src/modules/common/session/SessionService";
import {TextResponseReader} from "src/modules/common/http/service/impl/TextResponseReader";
import {NullResponseReader} from "src/modules/common/http/service/impl/NullResponseReader";
import {LoanDetailsService} from "src/modules/flow/loan-details/service/LoanDetailsService";
import {StripeCardService} from "src/modules/flow/payment/service/StripeCardService";
import {SnackbarService} from "src/modules/snackbar/service/SnackbarService";
import {StripeService} from "src/modules/stripe/service/StripeService";
import {StripePingService} from "src/modules/stripe/service/StripePingService";
import {PlaidService} from "src/modules/plaid/service/PlaidService";


export const NullResponseReaderSingleton = new NullResponseReader();
export const TextResponseReaderSingleton = new TextResponseReader(NullResponseReaderSingleton);
export const JsonResponseReaderSingleton = new JsonResponseReader(TextResponseReaderSingleton);
export const BrowserServiceSingleton = new BrowserService()
export const HttpServiceSingleton = new HttpService(BrowserServiceSingleton, JsonResponseReaderSingleton);
export const StripeServiceSingleton = new StripeService(HttpServiceSingleton)
export const SessionServiceSingleton = new SessionService(BrowserServiceSingleton);
export const LoanDetailsServiceSingleton = new LoanDetailsService(HttpServiceSingleton, SessionServiceSingleton);
export const SnackbarServiceSingleton = new SnackbarService();
export const StripeCardServiceSingleton = new StripeCardService();
export const PaymentServiceSingleton = new PaymentService(HttpServiceSingleton, BrowserServiceSingleton);
export const StripePingServiceSingleton = new StripePingService(HttpServiceSingleton);
export const PlaidServiceSingleton = new PlaidService(HttpServiceSingleton);
