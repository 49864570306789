import React from "react";
import {PaymentMethod} from "../../../model/PaymentMethod";
import {PrePayCreditCardInstructions} from "./credit-card/PrePayCreditCardInstructions";
import {PrePayAchInstructions} from "./ach/PrePayAchInstructions";
import {LoanData} from "../../../../loan-details/model/LoanData";

interface Props {
    method: PaymentMethod;
    address: string;
    loan: LoanData;
}

export class PrePayInstructionsMethodSwitch extends React.Component<Props> {

    render() {
        switch (this.props.method) {
            case PaymentMethod.CREDIT_CARD: {
                return <PrePayCreditCardInstructions loan={this.props.loan}
                                                     address={this.props.address}/>
            }
            case PaymentMethod.ACH: {
                return <PrePayAchInstructions loan={this.props.loan}
                                              address={this.props.address}/>;
            }
        }
    }

}