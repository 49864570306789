import {ThemeUtil} from "../../../util/ThemeUtil";

export class LoanNumberPromptThemes {

    public static readonly TEXT_INPUT_THEME = ThemeUtil.usingGlobalTheme({
        //todo: underlie!
    });

    public static readonly ERROR_MESSAGE_THEME = ThemeUtil.usingGlobalTheme({
        overrides: {
            MuiTypography: {
                root: {
                    margin: '3px 14px 0;'
                },
                body1: {
                    lineHeight: '0'
                }
            }
        }
    });

}