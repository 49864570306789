import React from "react";
import {LoanData} from "../../model/LoanData";
import "../../../../app/tileable/instructions/component/Instructions.scss";
import {CallForHelpLink} from "../../../../app/tileable/instructions/contats/CallForHelpLink";
import {EmailForHelpLink} from "../../../../app/tileable/instructions/contats/EmailForHelpLink";
import {LoanType} from "../../model/LoanType";
import {LoanTypeUtil} from "../../../../util/LoanTypeUtil";

interface Props {
    loan: LoanData;
}

export class LoanDetailsInstructions extends React.Component<Props> {
    //I love my Olenka <3


    render() {
        return <>

            <h1>Hi there, <br/>
                <span className="white-text">{this.props.loan.borrower.firstName}.</span>
            </h1>

            <p>Looks like you’re here to complete your <strong>{this.getPaymentType()}!</strong></p>

            <p>Check over your loan information real quick to make sure everything looks right, and then proceed to
                the
                payment page! </p>

            <p>Have questions? You can always <CallForHelpLink support={this.props.loan.support}>call</CallForHelpLink>
                &nbsp;or&nbsp;
                <EmailForHelpLink
                    support={this.props.loan.support}>email</EmailForHelpLink> {this.getPaymentAgent()} for help!
            </p>

        </>
    }

    private getPaymentType = () => {
        let type = this.props.loan.type;
        switch (type) {
            case LoanType.APPRAISAL_FEE_LOAN:
                return 'appraisal payment'
            case LoanType.INTEREST_LOAN:
                return 'first loan payment'
            default:
                return '';
        }
    }

    private getPaymentAgent = () => {
        return LoanTypeUtil.supportAgentOf(this.props.loan);
    }


}