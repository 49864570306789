import {ThemeUtil} from "../../../../../../../util/ThemeUtil";

export class PrePaymentAchThemeHolder {
    public static listTheme = ThemeUtil.usingGlobalTheme({
        overrides: {
            MuiList: {
                padding: {
                    'padding-top': '0',
                    'padding-bottom': '0',
                }
            }
        }
    });
}