import React from "react";
import {LoanData} from "src/modules/flow/loan-details/model/LoanData";
import {CallForHelpLink} from "src/modules/app/tileable/instructions/contats/CallForHelpLink";
import {EmailForHelpLink} from "src/modules/app/tileable/instructions/contats/EmailForHelpLink";

interface Props {
    loan: LoanData
    address: string;
}

export class PrePayCreditCardInstructions extends React.Component<Props> {
    render() {
        return <>
            <h1>Pay your <br/>
                <span className="white-text">Appraisal Fee.</span>
            </h1>

            <p>
                This payment will cover the <strong>Appraisal Fee </strong>that was required to
                complete your loan for the property at <strong>{this.props.address}</strong>.
            </p>

            <p>Have questions? You can always <CallForHelpLink support={this.props.loan.support}>call</CallForHelpLink>
                &nbsp;or&nbsp;
                <EmailForHelpLink support={this.props.loan.support}>email</EmailForHelpLink> your loan officer for help!
            </p>

        </>;
    }
}