import {Client, IFrame, IMessage} from "@stomp/stompjs";
import {WebSocketTopic} from "../model/WebSocketTopic";
import {MessageData} from "../model/MessageData";

type MessageCallback<T> = (message: MessageData<T>) => void;

export class WebSocketService {

    private client: Client;

    constructor() {
        const applicationHost = process.env.REACT_APP_APPLICATION_HOST;
        const wsUrl = `wss://${applicationHost}/socket/websocket`;

        let client = new Client({
            brokerURL: wsUrl,
        });
        client.onUnhandledFrame = this.unhandledFrame;
        client.onUnhandledMessage = this.unhandledMessage;
        this.client = client;
    }

    public activate() {
        if (this.client) {
            this.client.activate();
        }
    }

    public startSession<T>(topicName: WebSocketTopic, messageCallback: MessageCallback<T>) {
        if (this.client.connected) {
            console.log("ws client is active!");
            this.doSubscribe(topicName, messageCallback)
        } else {
            console.log("ws client will subscribe once active...!");
            this.client.onConnect = (_frame: IFrame) => {
                this.doSubscribe(topicName, messageCallback);
            };
        }
    }

    private doSubscribe<T>(topicName: WebSocketTopic, messageCallback: MessageCallback<T>) {
        console.log(`subscribed to ${topicName}`);
        let callback = (message: IMessage) => this.handleIMessage(message, messageCallback);
        this.client.subscribe(topicName, callback);
    }

    private handleIMessage<T>(message: IMessage, messageCallback: MessageCallback<T>) {
        let data = new MessageData<T>(message);
        console.log(data.data);
        messageCallback(data);
    }

    public stopSession(topicName: WebSocketTopic) {
        try {
            this.client.unsubscribe(topicName);
        } catch (e) {
            console.log(e);
        }
    }

    private readonly unhandledFrame = (frame: IFrame) => {
        this.unhandled(frame, 'frame');
    };
    private readonly unhandledMessage = (message: IMessage) => {
        this.unhandled(message, 'message');
    };

    private readonly unhandled = <T>(item: T, name: string) => {
        console.log(`unhandled ${name}`);
        console.log(item);
    }

    public deactivate(): Promise<any> {
        try {
            return this.client.deactivate();
        } catch (e) {
            console.log(e);
            return Promise.resolve();
        }
    }

}

export const WebSocketServiceSingleton = new WebSocketService()
