import React, {Component} from "react";
import  '../../../PrePayment.scss'
import {PlaidAccount, PlaidMetadata} from "../../../../../../../plaid/module/PlaidMetadata";
import {List, ListItem, ListItemText, Paper, ThemeProvider} from "@material-ui/core";
import {PrePaymentAchThemeHolder} from "../theme/PrePaymentAchThemeHolder";

interface Props {
    metadata?: PlaidMetadata,
    pickedAccount?: string,
    onPickedAccount: (accountId: string) => any;
}

export class PrePaymentAccountPicker extends Component<Props> {

    private static readonly theme = PrePaymentAchThemeHolder.listTheme;

    render() {
        if (this.hasAccounts()) {
            return this.getPicker();
        } else {
            return <></>;
        }
    }

    private hasAccounts = () => {
        let metadata = this.props.metadata;
        return metadata != null &&
            metadata.accounts != null &&
            metadata.accounts.length > 0;
    };

    private getPicker = () => {
        return (
            <div id="plaid_account_picker"
                 className="field">
                <Paper style={{
                    maxHeight: 200,
                    overflow: 'auto',
                    height: 'max-content',
                    borderRadius: '1px'
                }}>
                    <ThemeProvider theme={PrePaymentAccountPicker.theme}>
                        <List>
                            {this.mapMetadata()}
                        </List>
                    </ThemeProvider>
                </Paper>
            </div>
        );
    }

    private mapMetadata() {
        return this.props.metadata?.accounts?.map(acc => {
            return (
                <ListItem key={acc.id}
                          button
                          selected={this.isPicked(acc)}
                          onClick={() => {
                              this.props.onPickedAccount(acc.id)
                          }}>
                    <div className="account_item_wrapper">
                        <ListItemText primary={<strong>{acc.name}</strong>}
                                      secondary={`Ending in XXX${acc.mask}`}>
                        </ListItemText>
                        {this.isPicked(acc)}
                    </div>
                </ListItem>
            );
        });
    }

    private isPicked = (acc: PlaidAccount) => {
        return this.props.pickedAccount === acc.id
    }

}