import React from "react";
import {LoaderComponentDesktop} from "../../loader/component/LoaderComponentDesktop";
import {LoaderComponentMobile} from "../../loader/component/LoaderComponentMobile";

interface Props {
    loaded: boolean,
    keepChildren: boolean,
    loader: 'desktop' | 'mobile'
}


export abstract class LoadableComponent extends React.Component<Props> {

    render() {
        return (
            <>
                {this.getChildren()}
                {this.getLoader()}
            </>
        );
    }

    private getChildren = () => {
        if (this.shouldGetChildren()) {
            return this.props.children;
        } else {
            return <></>
        }
    }

    private getLoader = () => {
        if (this.shouldGetLoader()) {
            switch (this.props.loader) {
                case "desktop":
                    return <LoaderComponentDesktop/>
                case "mobile":
                    return <LoaderComponentMobile/>
            }
        } else {
            return <></>
        }
    }

    private shouldGetChildren = () => {
        let props = this.props;
        return props.keepChildren || props.loaded;
    }

    private shouldGetLoader = () => {
        return !this.props.loaded;
    }

}