import React from "react";
import {PaymentState} from "../../model/PaymentState";
import {LoanData} from "../../../loan-details/model/LoanData";
import {PaymentMethod} from "../../model/PaymentMethod";
import {AddressUtil} from "../../../../util/AddressUtil";
import {PrePayInstructionsMethodSwitch} from "./pre-pay/PrePayInstructionsMethodSwitch";
import {PostPayInstructions} from "./post-pay/PostPayInstructions";

interface Props {
    state: PaymentState;
    method: PaymentMethod;
    loan: LoanData;
}

export class PaymentInstructions extends React.Component<Props> {

    render() {
        switch (this.props.state) {
            case PaymentState.PRE_PAYMENT: {
                let method = this.props.method;
                return <PrePayInstructionsMethodSwitch method={method}
                                                       loan={this.props.loan}
                                                       address={this.address()}/>
            }
            case PaymentState.POST_PAYMENT: {
                let firstName = this.props.loan.borrower.firstName;
                return <PostPayInstructions firstName={firstName}/>
            }
            case PaymentState.IN_PAYMENT: {
                let firstName = this.props.loan.borrower.firstName;
                return <PostPayInstructions firstName={firstName}/>
            }
            default: {
                return <></>
            }

        }
    }

    private address = (): string => {
        let property = this.props.loan.property;
        return AddressUtil.toString(property);
    }

}