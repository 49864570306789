import {createHashHistory, History} from "history";
import {RedirectErrorParams} from "../../flow/error/model/RedirectErrorParams";
import {RedirectType} from "../../flow/error/model/RedirectType";

export class BrowserService {

    public history: History = createHashHistory();

    redirectOnParams = (params: RedirectErrorParams | any) => {
        let location = this.redirectLocation(params);
        this.redirectTo(location, params);
    }

    private redirectLocation(params: any) {
        try {
            let redirectType = params.redirectType as RedirectType;
            switch (redirectType) {
                case RedirectType.ERROR_PAGE:
                    return '/error';
                default:
                    return '/';
            }
        } catch (e) {
            return '/'
        }
    }

    redirectTo = <T>(url: string,
                     state: T | null = null) => {
        this.getHistory().push({
            pathname: url,
            state: state
        })
    }

    openNewTab(url: string) {
        window.open(url);
    }

    callToLink(phone: string): string {
        return `callto:${phone}`;
    }

    mailToLink(email: string): string {
        return `mailto:${email}`;
    }

    callTo(phone: string) {
        let link = this.callToLink(phone);
        this.openNewTab(link);
    }

    mailTo(email: string) {
        let link = this.mailToLink(email);
        this.openNewTab(link);
    }


    copyToClipboard(value: string): Promise<void> {
        return navigator.clipboard.writeText(value);
    }

    public getHistory = (): History => {
        if (this.history == null) {
            throw Error('History has not been initialised yet!');
        }
        return this.history;
    }

    readUrlParamsAs = <T = any>(url: URLSearchParams | null = null): T => {
        let urlParams = url != null ? url : this.readUrlParams();
        let entries = urlParams.entries();
        let object = Object.fromEntries(entries) as any;
        return object as T;
    }

    readUrlParams = (): URLSearchParams => {
        return new URLSearchParams(window.location.search);
    }

    removeUrlParams = () => {
        if (window.location.search.length != null) {
            window.history.replaceState(null, document.title, window.location.pathname);
        }
    }

    hasNavigationType = (navigationType: NavigationType): boolean => {
        let performanceEntries = window.performance.getEntriesByType("navigation");
        return performanceEntries.filter(e => e instanceof PerformanceNavigationTiming)
            .map(e => e as PerformanceNavigationTiming)
            .map(e => e.type)
            .includes(navigationType);
    }

}

