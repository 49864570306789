import React, {Component} from "react";
import {OptionalTableCell} from "../../../shared/component/OptionalTableCell";

export class LoanDetailsTablePaymentDue extends Component<{ paymentDue: string }> {

    render() {
        return <OptionalTableCell title='First Payment Due'>
            {this.props.paymentDue}
        </OptionalTableCell>;
    }

}