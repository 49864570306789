import {StripeCardElement, StripeCardElementChangeEvent, StripeElements} from "@stripe/stripe-js";

type Callback = (event: StripeCardElementChangeEvent) => any;

export class StripeCardService {

    private readonly DEFAULT_PLACEHOLDER_FONT = '1rem';

    createCard = (elements: StripeElements,
                  onChange: Callback) => {
        let card = this.doCreateCard(elements);
        this.addCardChangeHandler(card, onChange);
        this.checkWindowSize(card);
        return card;
    }

    private addCardChangeHandler = (card: StripeCardElement, onChange: Callback) => {
        card.on('change', onChange);
    }

    private doCreateCard = (elements: StripeElements): StripeCardElement => {
        // @ts-ignore
        return elements.create('card', {
            hidePostalCode: true
        });
    }

    private checkWindowSize = (card: StripeCardElement) => {
        this.updateCardFontSize(card, this.DEFAULT_PLACEHOLDER_FONT);

    }

    private updateCardFontSize = (card: StripeCardElement, fontSize: string) => {
        card.update({
            style: {
                base: {
                    fontSize: fontSize,
                    '::placeholder': {
                        fontSize: fontSize
                    }
                }
            }
        })
    }
}