export function toPublicUrl(path: string) {
    let publicUrl = process.env.PUBLIC_URL;
    return publicUrl + path;
}

export function getAbsoluteUrl(relativeUrl: string) {
    if (relativeUrl[0] !== '/') {
        relativeUrl = '/' + relativeUrl;
    }
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    console.log(process.env);
    console.log('Backend url ' + backendUrl)
    return backendUrl + relativeUrl;

}