import {SnackbarWrapper} from "../component/wrapper/SnackbarWrapper";
import React from "react";
import {SnackbarThemeHolder} from "../holder/SnackbarThemeHolder";
import {Theme} from "@material-ui/core";

export class SnackbarService {

    public ref?: React.RefObject<SnackbarWrapper>;
    public static readonly DEFAULT_ERROR_MESSAGE = 'Something went wrong, but we are already fixing that!';

    public success = (message: string) => {
        this.message(message, SnackbarThemeHolder.GREEN_THEME);
    }

    public error = (message: string = SnackbarService.DEFAULT_ERROR_MESSAGE) => {
        if (message == null || message.length === 0) {
            message = SnackbarService.DEFAULT_ERROR_MESSAGE
        }
        this.message(message, SnackbarThemeHolder.RED_THEME);
    }

    public message = (message: string, theme: Theme = SnackbarThemeHolder.GREY_THEME) => {
        if (this.ref && this.ref.current) {
            this.ref.current.setState({
                show: true,
                message: message,
                theme: theme
            })
        }
    }

}