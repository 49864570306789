import React from "react";
import {IconButton, Slide, Snackbar, Theme, ThemeProvider} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {SnackbarThemeHolder} from "../../holder/SnackbarThemeHolder";

class State {
    show: boolean = false;
    message?: string;
    theme: Theme = SnackbarThemeHolder.GREY_THEME;
}

export class SnackbarWrapper extends React.Component<any, State> {

    private readonly timeout: number = 1000;

    constructor(props: any) {
        super(props);
        this.state = new State();
    }

    render() {
        return (
            <ThemeProvider theme={this.state.theme}>
                <Snackbar
                    open={this.state.show}
                    autoHideDuration={this.timeout}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={this.handleClose}
                    message={this.state.message}
                    TransitionComponent={Slide}
                    action={
                        <React.Fragment>
                            <IconButton size="small"
                                        color="inherit"
                                        onClick={this.handleClose}>
                                <CloseIcon fontSize="small"/>
                            </IconButton>
                        </React.Fragment>
                    }
                />
            </ThemeProvider>

        );
    }

    private handleClose = () => {
        this.setState({
            show: false,
            message: undefined,
        })
    };

}