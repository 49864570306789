export class SessionUtil {

    private static storage = sessionStorage;

    public static store = <T>(key: string, item: T): T => {
        SessionUtil.storage.setItem(key, JSON.stringify(item));
        return item;
    }

    public static get = <T>(key: string): T | null => {
        let item = SessionUtil.storage.getItem(key);
        if (item) {
            return JSON.parse(item) as T;
        } else {
            return null;
        }
    }

    public static remove = (key: string) => {
        SessionUtil.storage.removeItem(key);
    }

    public static clear = () => {
        SessionUtil.storage.clear();
    }

}