import {ResponseReader} from "./ResponseReader";

export abstract class HeaderResponseReader extends ResponseReader {

    public supports(resp: Response): boolean {
        let headers = resp.headers;
        let contentTypeHeader = headers.get('Content-type');
        let contentTypeValue = this.contentType();
        return contentTypeHeader != null && contentTypeHeader.includes(contentTypeValue);
    }

    protected abstract contentType(): string;

}