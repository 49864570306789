import React from "react";
import   "../../../../../App.scss";

export class Body extends React.Component {
    render() {
        return (
            <div className="payment-page-section">
                <div className="content-inner-wrapper">
                    {this.props.children}
                </div>
            </div>
        );
    }
}