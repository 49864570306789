import React, {Component} from "react";
import {PrePaymentSubmitButton} from "../../../pre-payment-submit-button/PrePaymentSubmitButton";
import {container} from "tsyringe";
import {PlaidService} from "../../../../../../../plaid/service/PlaidService";
import {PlaidServiceSingleton} from "src/Context";

interface Props {
    submitButtonDisabled: boolean,
    publicToken?: string;
    pickedAccountId?: string;
}

export class PrePaymentAchSubmitButton extends Component<Props> {

    private readonly plaidService = PlaidServiceSingleton;

    private sendPaymentRequest = (): Promise<any> => {
        let publicToken = this.props.publicToken;
        let pickedAccountId = this.props.pickedAccountId;
        if (publicToken && pickedAccountId) {
            return this.plaidService.sendPaymentRequest(publicToken, pickedAccountId, true); //todo this is bad
        } else {
            console.log('token or metadata is null!!!', publicToken, pickedAccountId);
            return Promise.reject();
        }
    };

    render() {
        return <PrePaymentSubmitButton
            sendPaymentRequest={this.sendPaymentRequest}
            submitButtonDisabled={this.props.submitButtonDisabled}
        />

    }
}