import React from "react";
import {ValueEvent} from "../../../common/model/ValueEvent";
import {RouteComponentProps} from 'react-router-dom';
import {LoanNumberPromptInput} from "./LoanNumberPromptInput";
import {LoanNumberPromptHeader} from "./LoanNumberPromptHeader";
import {LoanNumberPromptError} from "./LoanNumberPromptError";
import {LoanNumberPromptInstructions} from "./instructions/LoanNumberPromptInstructions";
import {TileableComponent} from "../../../app/tileable/TileableComponent";
import {RedirectErrorBoundary} from "../../../app/error/RedirectErrorBoundary";
import {LoanType} from "../../loan-details/model/LoanType";
import {RedirectErrorParams} from "../../error/model/RedirectErrorParams";

interface State {
    hasErrorMessage: boolean;
    errorMessage?: string;
    supportEmail?: string;
    supportPhone?: string;
    supportType?: LoanType;
}

export class LoanNumberPrompt extends React.Component<RouteComponentProps<any, any, RedirectErrorParams | undefined>, State> {

    constructor(props: any) {
        super(props);
        let params = this.props.location.state;
        this.state = {
            hasErrorMessage: this.errorMessageHasLength(params?.error),
            errorMessage: params?.error || '',
            supportEmail: params?.email,
            supportPhone: params?.phone,
            supportType: params?.type
        }
    }

    private errorMessageHasLength = (errorMessage?: string) => {
        return errorMessage != null && errorMessage.length > 0;
    }

    private handleChange = (event: ValueEvent) => {
        this.removeErrorMessage();
    }

    private removeErrorMessage = () => {
        this.setState({
            errorMessage: '',
            hasErrorMessage: false
        });
    }


    render() {
        return <RedirectErrorBoundary>
            <TileableComponent instructions={this.instructions()}>
                <LoanNumberPromptHeader/>
                <LoanNumberPromptInput onInputUpdate={this.handleChange}
                                       error={this.state.hasErrorMessage}/>
                <LoanNumberPromptError errorMessage={this.state.errorMessage}
                                       phone={this.state.supportPhone}
                                       email={this.state.supportEmail}
                                       type={this.state.supportType}/>
            </TileableComponent>
        </RedirectErrorBoundary>

    };

    instructions = () => {
        return <LoanNumberPromptInstructions/>
    };


}