import React from "react";
import {RouteComponentProps} from 'react-router';
import {LoanData} from "../model/LoanData";
import {LoanDetailsTable} from "./loadn-details-table/LoanDetailsTable";
import {
    LoanNumberPromptInstructions
} from "../../loan_number_prompt/component/instructions/LoanNumberPromptInstructions";
import {LoanDetailsInstructions} from "./loan-details-instructions/LoanDetailsInstructions";
import {TileableComponent} from "../../../app/tileable/TileableComponent";
import {RedirectErrorBoundary} from "../../../app/error/RedirectErrorBoundary";
import {
    BrowserServiceSingleton,
    LoanDetailsServiceSingleton,
    PaymentServiceSingleton,
    SessionServiceSingleton
} from "src/Context";

interface Params {
    loanNumber: string;
}

interface State {
    loader: boolean;
    loanData?: LoanData;
}

export class LoanDetails extends React.Component<RouteComponentProps<Params>, State> {

    private readonly loanDetailsService = LoanDetailsServiceSingleton;
    private readonly browserService = BrowserServiceSingleton;
    private readonly paymentService = PaymentServiceSingleton;
    private readonly sessionService = SessionServiceSingleton;

    constructor(props: any) {
        super(props);
        this.state = {
            loader: true,
        }
    }

    componentDidMount = () => {
        let loanNumber = this.props.match.params.loanNumber;
        this.loanDetailsService.loginByLoanNumber(loanNumber)
            .then(this.loanDetailsService.fetchLoanDetail)
            .then((loanData) => {
                this.setState({
                    loader: false,
                    loanData: loanData
                });
            })
            .catch(console.log);
    }

    proceedToPayment = () => {
        this.setState({loader: true})
        this.paymentService.fetchStatus()
            .then(info => this.sessionService.storePaymentInfo(info))
            .then(() => this.browserService.redirectTo(`/payment`));
    }

    render() {
        return (
            <RedirectErrorBoundary>
                <TileableComponent instructions={this.getInstructions()}
                                   loaded={!this.state.loader}>
                    {this.getLoanDetailsTable()}
                </TileableComponent>
            </RedirectErrorBoundary>
        );
    }

    private getInstructions() {
        if (this.state.loanData != null) {
            let loan = this.state.loanData as LoanData;
            return <LoanDetailsInstructions loan={loan}/>
        } else {
            return <LoanNumberPromptInstructions/>
        }
    }

    private getLoanDetailsTable() {
        return <LoanDetailsTable loan={this.state.loanData as LoanData}
                                 proceedToPayment={this.proceedToPayment}/>
    }

}