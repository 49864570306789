import {LoanData} from "../../flow/loan-details/model/LoanData";
import {SessionUtil} from "../../util/SessionUtil";

import {PaymentInfo} from "../../flow/payment/model/PaymentInfo";
import {BrowserService} from "../browser/BrowserService";
import {RedirectError} from "../../app/error/RedirectErrorBoundary";


export class SessionService {

    constructor(private browserService: BrowserService) {
    }

    storeLoan = (loan: LoanData): LoanData => {
        return SessionUtil.store(this.Type.loan, loan);
    }

    storePaymentInfo = (info: PaymentInfo): PaymentInfo => {
        return SessionUtil.store(this.Type.paymentInfo, info);
    }

    getLoan = (): LoanData => {
        let loan = SessionUtil.get<LoanData>(this.Type.loan);
        return this.getOrRedirect(loan);
    }

    getPaymentInfo = (): PaymentInfo => {
        let info = SessionUtil.get<PaymentInfo>(this.Type.paymentInfo);
        return this.getOrRedirect(info);
    }

    removePaymentInfo = () => {
        SessionUtil.remove(this.Type.paymentInfo);
    }

    // @ts-ignore
    private getOrRedirect = <T>(item: T | null): T => {
        if (item != null) {
            return item;
        } else {
            throw new RedirectError("/");
        }
    }

    private Type = {
        loan: 'loan',
        paymentInfo: 'paymentInfo'
    }

}