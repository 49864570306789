import React from "react";
import {ContactInfo} from "../../../../flow/loan-details/model/ContactInfo";
import {BrowserServiceSingleton} from "src/Context";

interface Props {
    support: ContactInfo;
}

export class EmailForHelpLink extends React.Component<Props> {

    private readonly browserService = BrowserServiceSingleton;

    private mailTo = (): string => {
        let email = this.props.support.email;
        return this.browserService.mailToLink(email);
    }

    render() {
        return <a href={this.mailTo()}>{this.props.children}</a>
    }

}