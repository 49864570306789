import {ResponseReader} from "../ResponseReader";
import {HttpResponse} from "../../model/HttpResponse";
import {PromiseUtil} from "../../../../util/PromiseUtil";


export class NullResponseReader extends ResponseReader {

    next(): ResponseReader | null {
        return null;
    }

    read(resp: Response): Promise<HttpResponse> {
        let item = this.assignResponseData(null, resp);
        return PromiseUtil.of(item);
    }

    supports(resp: Response): boolean {
        return true;
    }

}