import React from "react";
import  './SecurityNote.scss';
import {toPublicUrl} from "src/modules/util/UrlUtil";

export class SecurityNote extends React.Component {

    render() {
        return (
            <div id="security-note">
                <img src={toPublicUrl("/images/lock.png")} alt="lock"/>
                <p>Your information is always private.</p>
            </div>
        );
    }

}