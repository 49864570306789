import {ThemeUtil} from "../../../util/ThemeUtil";

export class PaymentThemeHolder {
    static I_AGREE_CHECKBOX = ThemeUtil.usingGlobalTheme({
        overrides: {
            MuiCheckbox: {
                root: {
                    padding: '0px !important'
                }
            }
        }
    },)
}