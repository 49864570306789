import React from "react";
import  './LoanNumberPromptInstructions.scss'
import {toPublicUrl} from "src/modules/util/UrlUtil";

export class LoanNumberPromptInstructions extends React.Component {

    render() {
        return <>
            <h1>Paying is <br/>
                <span>
                    <img src={toPublicUrl("/images/simpl_reverse_inline.png")} alt="simpl." id="simpl-inline-logo"/>
                </span>
            </h1>
            <h4>Use our <strong>simpl</strong> payment system to complete your online payment. It's easy, quick, and
                totally secure.</h4>
        </>;
    }

}