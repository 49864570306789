import  './PostPayment.scss'
import React, {Component} from "react";
import {PaymentResult} from "../../../../stripe/model/PaymentResult";
import {PaymentMethod} from "../../model/PaymentMethod";
import {LoanData} from "../../../loan-details/model/LoanData";
import {PostPaymentHeader} from "./header/PostPaymentHeader";
import {PostPaymentTable} from "./table/PostPaymentTable";
import {PostPaymentNote} from "./note/PostPaymentNote";
import {PostPaymentLogo} from "./logo/PostPaymentLogo";

interface Props {
    result: PaymentResult,
    method: PaymentMethod,
    loan: LoanData
}

export class PostPayment extends Component<Props> {

    render() {
        return (
            <div id="payment-summary">
                <PostPaymentHeader/>
                <PostPaymentTable result={this.props.result}/>
                <PostPaymentNote loan={this.props.loan}/>
                <PostPaymentLogo/>
            </div>
        );
    }

}