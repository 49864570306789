import React, {Component} from "react";
import {LoanOfficer} from "../../model/LoanOfficer";
import {LoanDetailsTableLoanOfficerName} from "./LoanDetailsTableLoanOfficerName";
import {LoanDetailsTableLoanOfficerPhone} from "./LoanDetailsTableLoanOfficerPhone";
import {LoanDetailsTableLoanOfficerEmail} from "./LoanDetailsTableLoanOfficerEmail";

export class LoanDetailsTableLoanOfficer extends Component<{ loanOfficer: LoanOfficer }> {
    render() {
        return <>
            <LoanDetailsTableLoanOfficerName fullName={this.props.loanOfficer.fullName}/>

            <LoanDetailsTableLoanOfficerPhone phone={this.props.loanOfficer.contactInfo.workingPhone}/>

            <LoanDetailsTableLoanOfficerEmail email={this.props.loanOfficer.contactInfo.email}/>
        </>;
    }
}