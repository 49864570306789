import React from "react";
import {LoanData} from "../loan-details/model/LoanData";
import {PaymentState} from "./model/PaymentState";
import {PostPayment} from "./component/post-payment/PostPayment";
import {PaymentResult} from "../../stripe/model/PaymentResult";
import {PaymentStatus} from "./service/PaymentService";
import {WebSocketServiceSingleton} from "../../websocket/service/WebSocketService";
import {WebSocketTopic} from "../../websocket/model/WebSocketTopic";
import {MessageData} from "../../websocket/model/MessageData";
import {InPayment} from "./component/in-payment/InPayment";
import {PaymentInfo} from "./model/PaymentInfo";
import {EventTypeHolder} from "../../util/EventUtil";
import {PrePaymentMethodSwitch} from "./component/pre-payment/pre-payment-method-switch/PrePaymentMethodSwitch";
import {TileableComponent} from "../../app/tileable/TileableComponent";
import {PaymentInstructions} from "./component/instructions/PaymentInstructions";
import {PaymentMethod} from "./model/PaymentMethod";
import {EventRegistry} from "../../app/event-aware/EventRegistry";
import {RedirectType} from "../error/model/RedirectType";
import {BrowserServiceSingleton, SessionServiceSingleton, StripePingServiceSingleton} from "src/Context";

interface Props {
}

interface State {
    loading: boolean;
    paymentInfo: PaymentInfo;
    loan: LoanData;
}

export class PaymentStateSwitch extends React.Component<Props, State> {

    private readonly browserService = BrowserServiceSingleton;
    private readonly wsService = WebSocketServiceSingleton;
    private readonly sessionService = SessionServiceSingleton;
    private readonly stripePingService = StripePingServiceSingleton;
    private readonly eventRegistry = new EventRegistry();

    constructor(p: Props) {
        super(p);
        let loan = this.sessionService.getLoan();
        let info = this.sessionService.getPaymentInfo();
        let loading = this.isLoading(info);
        this.state = {
            loading: loading,
            loan: loan,
            paymentInfo: info,
        }
    }


    private isLoading = (info: PaymentInfo) => {

        let state = info.status.state;
        if (state === PaymentState.IN_PAYMENT) {
            return true;
        } else {
            let isPrePayment = state === PaymentState.PRE_PAYMENT;
            let isCreditCard = info.method === PaymentMethod.CREDIT_CARD;
            return isPrePayment && isCreditCard;
        }
    };

    componentDidMount() {
        this.wsService.activate();
        this.wsService.startSession(WebSocketTopic.PAYMENT_STATUS, this.handlePayment)
        this.eventRegistry.pushAll([
            {
                name: EventTypeHolder.stripeCardMounted,
                callback: () => {
                    this.setState({
                        loading: false
                    })
                }
            },
            {
                name: EventTypeHolder.paymentRequestSent,
                callback: () => {
                    this.setState({
                        loading: true
                    })
                }
            },
            {
                name: EventTypeHolder.paymentRequestComplete,
                callback: () => {
                    let info = this.state.paymentInfo;
                    info.status.state = PaymentState.IN_PAYMENT;
                    this.sessionService.storePaymentInfo(info);
                }
            },
            {
                name: EventTypeHolder.paymentRequestComplete,
                callback: () => {
                    console.log('initiating delayed ping...');
                    return this.stripePingService.delayedPing();
                }


            },
            {
                name: EventTypeHolder.paymentFailure,
                callback: () => {
                    this.setState({
                        loading: false
                    })
                }
            },
            {
                name: EventTypeHolder.inPaymentPing,
                callback: (evt: CustomEvent<PaymentStatus>) => this.onPaymentStatusUpdate(evt.detail)
            }
        ]);
    }

    instructions = () => {
        let state = this.state.paymentInfo.status.state;
        let loan = this.state.loan;
        let method = this.state.paymentInfo.method;
        return <PaymentInstructions
            loan={loan}
            method={method}
            state={state}/>
    }

    render() {
        return (
            <TileableComponent instructions={this.instructions()}
                               loaded={!this.state.loading}
                               keepChildren={true}>
                {this.switchPaymentState()}
            </TileableComponent>
        );
    }

    private handlePayment = (message: MessageData<PaymentStatus>) => {
        let status = message.data;
        this.onPaymentStatusUpdate(status);
    }

    private onPaymentStatusUpdate = (status: PaymentStatus) => {
        this.handleSingleResult(status.result);
        this.handlePaymentEnd(status);
    };

    private handleSingleResult = (result: PaymentResult) => {
        if (result.paid) {
            this.browserService.openNewTab(result.receiptUrl);
        } else {
            let errorParams = result.errorParams;
            if (errorParams) {
                if (errorParams.redirectType !== RedirectType.PAYMENT_CC_PAGE) {
                    this.browserService.redirectOnParams(errorParams);
                }
            }
        }
    };

    private handlePaymentEnd = (status: PaymentStatus) => {
        let info = this.assignPaymentInfo(status);
        this.sessionService.storePaymentInfo(info);
        this.setState({
            paymentInfo: info,
            loading: false
        });
    };

    private assignPaymentInfo = (status: PaymentStatus): PaymentInfo => {
        let info = this.state.paymentInfo;
        Object.assign(info?.status, {
            state: status.state,
            result: status.result
        })
        return info as PaymentInfo;
    };

    private switchPaymentState = () => {
        let state = this.state.paymentInfo?.status.state;
        switch (state) {
            case PaymentState.PRE_PAYMENT:
                return this.getPrePaymentElement();
            case PaymentState.POST_PAYMENT:
                return this.getPostPaymentElement();
            case PaymentState.IN_PAYMENT:
                return this.getInPaymentElement();
            default:
                console.log('here!')
                return <></>;
        }

    };

    private getPrePaymentElement = () => {
        return <PrePaymentMethodSwitch
            disabled={this.state.loading}
            info={this.state.paymentInfo as PaymentInfo}
            loan={this.state.loan as LoanData}>
        </PrePaymentMethodSwitch>
    };


    private getPostPaymentElement = () => {
        let result = this.state.paymentInfo.status.result;
        let method = this.state.paymentInfo.method;
        let loan = this.state.loan;
        return <PostPayment
            result={result}
            method={method}
            loan={loan}/>;
    }

    private getInPaymentElement = () => {
        return <InPayment/>
    };

    componentWillUnmount() {
        this.eventRegistry.clear();
        this.wsService.stopSession(WebSocketTopic.PAYMENT_STATUS);
        this.wsService.deactivate();
    }

}