import React from "react";
import {LoanData} from "../../../../loan-details/model/LoanData";
import {LoanDetailsCaption} from "../../../../loan-details-caption/component/LoanDetailsCaption";

interface Props {
    loan: LoanData
}

export class PrePaymentWrapperFooter extends React.Component<Props> {

    render() {
        return <>
            <LoanDetailsCaption
                support={this.props.loan.support}
                type={this.props.loan.type}/>
        </>;
    }

}