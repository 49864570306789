import React from "react";
import {RedirectErrorBoundary} from "../app/error/RedirectErrorBoundary";
import {PaymentStateSwitch} from "./payment/PaymentStateSwitch";

export class Payment extends React.Component {

    render() {
        return <RedirectErrorBoundary>
            <PaymentStateSwitch/>
        </RedirectErrorBoundary>;
    }

}