import React from "react";
import {Redirect} from "react-router-dom";
import {CustomError} from "ts-custom-error";

interface State {
    hasError: boolean,
    error?: RedirectError<any>
}

export class RedirectError<T> extends CustomError {

    constructor(public readonly url: string,
                public readonly state?: T) {
        super();
    }
}

export class RedirectErrorBoundary extends React.Component<any, State> {

    constructor(p: any) {
        super(p);
        this.state = {
            hasError: false
        }
    }

    render() {
        if (this.state.hasError) {
            return <Redirect to={{
                pathname: this.state.error?.url,
                state: this.state.error?.state
            }}/>
        } else {
            return <>
                {this.props.children}
            </>

        }
    }

    componentDidCatch(error: Error,
                      errorInfo: React.ErrorInfo) {
        console.log('here!')
        console.log(error);
        if (error instanceof RedirectError) {
            let redirectError = error as RedirectError<any>;
            this.setState({
                hasError: true,
                error: redirectError,
            });
        }

    }

}