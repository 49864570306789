import React from "react";
import  './../PrePayment.scss'

export class PrePaymentWrapperHeader extends React.Component {

    render() {
        return <>

            <div id="payment_page_title">
                <h3>
                    Enter Your Payment:
                </h3>
                <p>
                    {this.props.children}
                </p>
            </div>

        </>;
    }

}