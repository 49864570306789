import React from "react";
import {PaymentInfo} from "../../../model/PaymentInfo";
import {LoanData} from "../../../../loan-details/model/LoanData";
import {PaymentMethod} from "../../../model/PaymentMethod";
import {PrePaymentCreditCard} from "../credit-card/pre-payment-credit-card/PrePaymentCreditCard";
import {PrePaymentAch} from "../ach/pre-payment-ach/PrePaymentAch";
import {PrePaymentCreditCardWrapper} from "../wrapper/PrePaymentCreditCardWrapper";
import {PrePaymentAchWrapper} from "../wrapper/PrePaymentAchWrapper";


interface Props {
    info: PaymentInfo;
    loan: LoanData;
    disabled: boolean;
}

export class PrePaymentMethodSwitch extends React.Component<Props> {

    render() {
        switch (this.props.info.method) {
            case PaymentMethod.CREDIT_CARD:
                return <PrePaymentCreditCardWrapper loan={this.props.loan}>
                    <PrePaymentCreditCard suggestedName={this.getSuggestedName()}
                                          fee={this.props.loan.fee}
                                          paymentInfo={this.props.info}
                                          disabled={this.props.disabled}
                    />
                </PrePaymentCreditCardWrapper>
            case PaymentMethod.ACH:
                return <PrePaymentAchWrapper loan={this.props.loan}>
                    <PrePaymentAch paymentInfo={this.props.info}
                                   fee={this.props.loan.paymentAmount}
                                   disabled={this.props.disabled}
                    />
                </PrePaymentAchWrapper>
        }
    }

    private getSuggestedName = () => {
        let loanData = this.props.loan;
        return `${loanData.borrower.firstName} ${loanData.borrower.lastName}`;
    };

}