import React from "react";
import  "../PostPayment.scss";
import {PostPaymentItem} from "../item/PostPaymentItem";
import {CallForHelpLink} from "../../../../../app/tileable/instructions/contats/CallForHelpLink";
import {EmailForHelpLink} from "../../../../../app/tileable/instructions/contats/EmailForHelpLink";
import {LoanData} from "../../../../loan-details/model/LoanData";
import {LoanTypeUtil} from "../../../../../util/LoanTypeUtil";

interface Props {
    loan: LoanData
}

export class PostPaymentNote extends React.Component<Props> {

    render() {
        let support = this.props.loan.support;
        return (
            <PostPaymentItem id="payment-summary-note">
                    <span>
                        You're all set.
                        You should receive an email confirmation to the same email you used to manage your loan process.
                        You can print this page, and please keep your confirmation email for your records.
                        As always, <CallForHelpLink support={support}>call</CallForHelpLink> or <EmailForHelpLink
                        support={support}>email</EmailForHelpLink> {this.supportAgent()} if you have any questions.
                    </span>
            </PostPaymentItem>
        );
    }

    private supportAgent = () => LoanTypeUtil.supportAgentOf(this.props.loan);
}