import {HttpResponse} from "../../model/HttpResponse";
import {ResponseReader} from "../ResponseReader";
import {HeaderResponseReader} from "../HeaderResponseReader";
import {TextResponseReader} from "./TextResponseReader";


export class JsonResponseReader extends HeaderResponseReader {

    constructor(private nextReader: TextResponseReader) {
        super();
    }

    public read(resp: Response): Promise<HttpResponse> {
        return resp.json()
            .then(json => this.assignResponseData(json, resp));
    }

    next(): ResponseReader {
        return this.nextReader;
    }

    protected contentType(): string {
        return "application/json";
    }
}