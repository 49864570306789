import React from "react";
import {container} from "tsyringe";
import {StripeService} from "../../../../../../stripe/service/StripeService";
import {Elements} from "@stripe/react-stripe-js";
import {PrePaymentCardElement} from "../pre-payment-card-element/PrePaymentCardElement";
import {PrePaymentNameOnCard} from "../pre-payment-name-on-card/PrePaymentNameOnCard";
import {PrePaymentCreditCardSubmitButton} from "../pre-payment-submit-button/PrePaymentCreditCardSubmitButton";
import {PaymentForm} from "../../../../model/PaymentForm";
import {PrePaymentFeeCreditCard} from "../../pre-payment-fee-credit-card/PrePaymentFeeCreditCard";
import {PaymentInfo} from "../../../../model/PaymentInfo";
import {PrePaymentAgreementCreditCard} from "../../pre-payment-agreement/PrePaymentAgreementCreditCard";
import {StripeServiceSingleton} from "src/Context";

interface Props {
    suggestedName: string,
    fee: string,
    paymentInfo: PaymentInfo,
    disabled: boolean
}

interface State {
    cardValidity: boolean;
    formValidity: boolean;
    submitButtonDisabled: boolean;
}

export class PrePaymentCreditCard extends React.Component<Props, State> {

    private readonly stripeService = StripeServiceSingleton;
    private readonly form = React.createRef<PaymentForm>();
    private readonly name = React.createRef<PrePaymentNameOnCard>();
    private readonly card = React.createRef<PrePaymentCardElement>();

    constructor(p: Props) {
        super(p);
        this.state = {
            cardValidity: false,
            formValidity: false,
            submitButtonDisabled: true
        }
        this.stripeService.initStripe(this.props.paymentInfo.stripePublicKey);
    }

    componentDidUpdate = (prevProps: Readonly<any>,
                          prevState: Readonly<State>,
                          snapshot?: any) => {
        if (this.isFormUpdated(prevState)) {
            this.setState({
                submitButtonDisabled: !this.state.cardValidity || !this.state.formValidity
            })
        }
    }

    private isFormUpdated = (prevState: Readonly<State>): boolean => {
        let cardValidityChanged = this.state.cardValidity !== prevState.cardValidity;
        let formValidityChanged = this.state.formValidity !== prevState.formValidity;
        return cardValidityChanged || formValidityChanged;
    };

    private handleFormChange = () => {
        let form = this.form.current as PaymentForm;
        this.setState({
            formValidity: form.checkValidity(),
        })
    }

    private handleCardValidityChange = (cardValidity: boolean) => {
        this.setState({
            cardValidity: cardValidity
        });
    }

    private errorCallback = (message: string): void => {
        this.card.current?.setState({
            errorMessage: message
        });
    }

    public hasErrorMessage = (): boolean => {
        let errorMessage = this.card.current?.state.errorMessage;
        return errorMessage != null && errorMessage.length > 0;
    }

    render() {
        return (

            <>
                <Elements stripe={this.stripeService.stripePromise()}>
                    <form ref={this.form} onChange={this.handleFormChange}>
                        <fieldset disabled={this.props.disabled}>
                            <PrePaymentFeeCreditCard fee={this.props.fee}/>

                            <PrePaymentNameOnCard ref={this.name} suggestedName={this.props.suggestedName}/>

                            <PrePaymentCardElement ref={this.card}
                                                   onCardValidityChanged={this.handleCardValidityChange}/>

                            <PrePaymentAgreementCreditCard/>

                            <PrePaymentCreditCardSubmitButton form={this.form}
                                                              cardElement={this.card.current?.getCardElement()}
                                                              submitButtonDisabled={this.state.submitButtonDisabled}
                                                              errorCallback={this.errorCallback}/>
                        </fieldset>
                    </form>
                </Elements>
            </>
        );
    }

}