import  './LoaderComponent.scss';
import React from "react";

export class LoaderComponentBackground extends React.Component {

    render() {
        return (
            <div className="background">
                {this.props.children}
            </div>
        );
    }

}