import React, {Component} from "react";
import {container} from "tsyringe";
import {StripePingService} from "../../../../stripe/service/StripePingService";
import {EventTypeHolder, EventUtil} from "../../../../util/EventUtil";
import {BrowserService} from "../../../../common/browser/BrowserService";
import {BrowserServiceSingleton, StripePingServiceSingleton} from "src/Context";


export class InPayment extends Component {

    private readonly stripePingService = StripePingServiceSingleton;
    private readonly browserService = BrowserServiceSingleton;

    componentDidMount() {

        let isReload = this.browserService.hasNavigationType('reload');
        if (isReload) {
            this.stripePingService.ping()
                .then(status => {
                    EventUtil.fireEvent(EventTypeHolder.inPaymentPing, status);
                });
        }
    }

    render() {
        return (
            <>
            </>
        );
    }

}