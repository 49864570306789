import React from "react";
import {PrePaymentAgreement} from "./PrePaymentAgreement";

export class PrePaymentAgreementAch extends React.Component {

    render() {
        return (
            <PrePaymentAgreement>
                I (we) hereby authorize Network Funding, L.P. and its successors, assigns, authorized agents including
                Dovenmuehle Mortgage, Inc. or any entity servicing my loan on their behalf (hereinafter called THE
                LENDER) as I indicate on the next screen to initiate 1) a mortgage payment debit to my (our) indicated
                Checking or Savings Account, and 2) debit the depository named for the indicated account. I (we)
                understand that if any debit entries under this authorization are returned for insufficient funds or
                otherwise dishonored, I (we) will promptly send THE LENDER the total monthly payment due, plus any late
                charge(s) or other fees due under my mortgage. I (we) authorize THE LENDER to electronically credit my
                (our) account if necessary, to correct erroneous debits. I (we) agree that ACH transactions I (we)
                authorize comply with federal law. I understand if my payment is returned by my bank, I may be charged a
                fee. This authorization is to remain in full force and effect until THE LENDER has received written
                notification from me (us) of its termination in such time and such manner as to afford THE LENDER a
                reasonable opportunity to act upon it. THE LENDER may terminate this agreement at any time, with written
                notice sent to me. I agree to the terms and conditions as outlined above PLEASE RETAIN THIS PAGE FOR
                YOUR RECORDS. AUTHORIZATION AGREEMENT FOR A ONE-TIME DRAFT. 9700 Richmond Avenue, Suite 320, Houston,
                Texas 77042
            </PrePaymentAgreement>
        );
    }
}