import React from "react";
import  "../PrePayment.scss";
import {Checkbox, ThemeProvider} from "@material-ui/core";
import {PaymentThemeHolder} from "../../../theme/PaymentThemeHolder";

export class PrePaymentAgreement extends React.Component {

    render() {
        return (
            <div className="checkbox_fieldset">
                <div className="checkbox_wrapper">
                    {/*div below is important! whithout it, checkbox outliner becomes huge af*/}
                    <div>
                        <ThemeProvider theme={PaymentThemeHolder.I_AGREE_CHECKBOX}>
                            <Checkbox defaultChecked={false}
                                      name={'agreedToTerms'}
                                      color={'primary'}
                                      required/>
                        </ThemeProvider>
                    </div>
                </div>
                <div className="checkbox_message">
                    {this.props.children}
                </div>
            </div>
        );
    }
}