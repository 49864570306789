export type CustomEventListener<T> = (event: CustomEvent<T>) => void;

export class EventUtil {

    public static readonly target: EventTarget = window;

    public static fireEvent = <T>(name: string, item: T | null = null, target: EventTarget = EventUtil.target) => {
        let event: CustomEvent<T> | Event;
        if (item != null) {
            event = new CustomEvent<T>(name, {
                detail: item
            });
        } else {
            event = new Event(name);
        }
        target.dispatchEvent(event);
    }

    public static onEvent = (name: string,
                             callback: EventListener,
                             target: EventTarget = EventUtil.target) => {
        target.addEventListener(name, callback as EventListener);
    }

    public static onCustomEvent = <T>(name: string,
                                      callback: CustomEventListener<T>,
                                      target: EventTarget = EventUtil.target) => {
        EventUtil.onEvent(name, callback as EventListener, target);
    }

    public static remove = <T = null>(name: string,
                                      callback: EventListener | CustomEventListener<T>,
                                      target: EventTarget = EventUtil.target) => {
        target.removeEventListener(name, callback as EventListener);
    }

}

export const EventTypeHolder = {
    stripeLoaded: 'stripeLoaded',
    stripeElementsLoaded: 'stripeElementsLoaded',
    stripeCardMounted: 'stripeCardMounted',
    paymentFailure: 'paymentFailure',
    paymentRequestSent: 'paymentRequestSent',
    paymentRequestComplete: 'paymentRequestComplete',
    inPaymentPing: 'inPaymentPing'
}