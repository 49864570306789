import React from "react";
import {SnackbarWrapper} from "../wrapper/SnackbarWrapper";
import {SnackbarServiceSingleton} from "src/Context";

export class AppSnackbar extends React.Component<any, any> {

    private readonly snackbarWrapperRef = React.createRef<SnackbarWrapper>();
    private readonly snackbarService = SnackbarServiceSingleton;

    componentDidMount() {
        this.snackbarService.ref = this.snackbarWrapperRef;
    }

    render() {
        return (
            <SnackbarWrapper ref={this.snackbarWrapperRef}/>
        );
    }

}