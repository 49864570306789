import React from "react";
import 'src/App.scss'
import './BrandLegalInfo.scss'
import {toPublicUrl} from "src/modules/util/UrlUtil";

interface State {
    nmls: string;
    terms: string;
    licensing: string;
}

export class BrandLegalInfo extends React.Component<any, State> {

    constructor(p: any) {
        super(p);

        this.state = {
            nmls: process.env.REACT_APP_NMLS,
            terms: process.env.REACT_APP_TERMS_URL,
            licensing: process.env.REACT_APP_LICENCING_URL
        }
    }

    render() {
        return (
            <div id="brand-legal-info" className="content-inner-wrapper">

                <div id="logo-container">

                    <img src={toPublicUrl("/images/nflp-logo-stamp-white.png")} alt="NFLP"/>

                </div>

                <div id="legal-container">

                    <h5>Powered by Network Funding, LP. </h5>

                    <h6>{this.getNMLS()} | {this.getTerms()} | {this.getLicensing()} | {this.getCopyright()}</h6>

                </div>

            </div>
        );
    }

    private getNMLS = () => <>NMLS #{this.state.nmls} | Equal Housing Lender</>;
    private getTerms = () => <a href={this.state.terms} target="_new">Terms</a>;
    private getLicensing = () => <a href={this.state.licensing} target="_new">Licensing</a>;
    private getCopyright = () => <> © {new Date().getFullYear()}</>;
}
