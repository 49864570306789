import React from "react";
import {PrePaymentAgreement} from "./PrePaymentAgreement";

export class PrePaymentAgreementCreditCard extends React.Component {

    render() {
        return (
            <PrePaymentAgreement>
                I hereby authorize Network Funding, L.P. to charge the amount stated above to this credit
                card.
                I understand these fees are to cover costs incurred in processing my application for a
                mortgage
                with Network Funding, L.P. and are non-refundable unless otherwise provided in writing by
                Network Funding, L.P.
                I hereby certify that the above named applicant gave verbal authorization to charge the
                amount
                stated above to the credit card shown. I further certify that a copy of this Authorization
                has been provided to the applicant.
            </PrePaymentAgreement>
        );
    }
}