import {createMuiTheme, Theme, ThemeOptions} from "@material-ui/core";
import GLOBAL_THEME from "../../GlobalCssTheme";

export class ThemeUtil {
    static usingGlobalTheme = (options?: ThemeOptions, ...args: ThemeOptions[]): Theme => {

        if (options == null && (args == null || args.length === 0)) {
            return GLOBAL_THEME;
        }

        return createMuiTheme(options, ...[GLOBAL_THEME, ...args]);
    }
}