import React from "react";
import  "./LoanNumberPromptTooltip.scss";
import Tooltip from "@material-ui/core/Tooltip";

export class LoanNumberPromptTooltip extends React.Component {
    render() {
        return <div className="tool-tip">
            <Tooltip title={this.getTitle()}>
                <div className="tip-button">
                    ?
                </div>
            </Tooltip>
        </div>;
    }

    private getTitle = () => <p>
        You can find your loan number in the SIMPL application or on your loan closing
        documents.
        If you need help, feel free to call us!
    </p>;
}