import React, {Component} from "react";
import  "./../PostPayment.scss";
import {toPublicUrl} from "src/modules/util/UrlUtil";

export class PostPaymentLogo extends Component {
    render() {
        return <div id="payment-summary-logo">
            <img src={toPublicUrl("/images/icons/nflp-logo.png")} alt={"NFLP"}/>
            <span>Network Funding, LP</span>
        </div>;
    }
}