import React from "react";
import {TextField} from "@material-ui/core";
import  './../PrePayment.scss'
import {PrePaymentFeeTooltipAch} from "../tooltip/PrePaymentFeeTooltipAch";

interface Props {
    fee: string
}

export class PrePaymentFeeAch extends React.Component<Props> {

    render() {
        return <div className="field">
            <TextField label={'Mortgage Payment Amount:'}
                       value={this.props.fee}
                       disabled={true}
                       fullWidth={true}
                       InputProps={{
                           endAdornment: <PrePaymentFeeTooltipAch/>
                       }}
            />
        </div>;
    }
}