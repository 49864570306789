import React from 'react';
import './App.scss';
import {AppRouter} from "./modules/app/router/component/AppRouter";
import {ThemeProvider} from '@material-ui/core';
import GLOBAL_THEME from "./GlobalCssTheme";
import {AppSnackbar} from "./modules/snackbar/component/app/AppSnackbar";

function App() {

    return (
        <div id="payment-page-wrapper">
            <ThemeProvider theme={GLOBAL_THEME}>
                <AppSnackbar/>
                <AppRouter/>
            </ThemeProvider>
        </div>
    );
}

export default App;
