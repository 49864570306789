import  "./../PostPayment.scss";
import React from "react";

export class PostPaymentItem extends React.Component<any> {

    render() {
        return (
            <div {...this.props} className="item">
                {this.props.children}
            </div>
        );
    }

}