import React from "react";
import {RedirectErrorBoundary} from "../../../app/error/RedirectErrorBoundary";
import {TileableComponent} from "../../../app/tileable/TileableComponent";
import {RouteComponentProps} from "react-router-dom";
import {RedirectErrorParams} from "../model/RedirectErrorParams";
import {LoanTypeUtil} from "../../../util/LoanTypeUtil";
import {LoanType} from "../../loan-details/model/LoanType";
import {LoanDetailsCaption} from "../../loan-details-caption/component/LoanDetailsCaption";
import {fallbackErrorMessage} from "src/modules/configuration/APPLICATION_CONFIGURATION";


interface P {

}

interface S {

}

export class ErrorPage extends React.Component<RouteComponentProps<P, any, RedirectErrorParams>, S> {

    render() {
        let state = this.props.location.state;
        let loanType = state?.type || LoanType.UNKNOWN;
        let contactInfo = {
            workingPhone: state?.phone || process.env.REACT_APP_WORKING_PHONE,
            email: state?.email || process.env.REACT_APP_WORKING_EMAIL
        }
        let message = state?.error || fallbackErrorMessage;
        return (
            <RedirectErrorBoundary>
                <TileableComponent instructions={this.instructions(loanType)}>
                    <div>
                    <span>
                        {message}
                    </span>
                    </div>
                    <div>
                        <LoanDetailsCaption support={contactInfo} type={loanType}/>
                    </div>
                </TileableComponent>
            </RedirectErrorBoundary>
        );
    }

    private instructions = (loanType: LoanType) => {
        let supportAgent = LoanTypeUtil.supportAgent(loanType);
        return <>
            <h1>
                Oops! Looks like something went wrong
            </h1>
            <p>
                But don't worry, <strong>{supportAgent}</strong> will be happy to help!
            </p>

        </>
    }

}