import React from "react";
import {LoanDetailsCaption} from "../../../loan-details-caption/component/LoanDetailsCaption";
import {LoanData} from "../../model/LoanData";
import {LoanDetailsTableFirstName} from "./LoanDetailsTableFirstName";
import {LoanDetailsTableLastName} from "./LoanDetailsTableLastName";
import {LoanDetailsTableLoanNumber} from "./LoanDetailsTableLoanNumber";
import {LoanDetailsTableLoanAmount} from "./LoanDetailsTableLoanAmount";
import {LoanDetailsTableFee} from "./LoanDetailsTableFee";
import {LoanDetailsTableProperty} from "./LoanDetailsTableProperty";
import {LoanDetailsTablePaymentDue} from "./LoanDetailsTablePaymentDue";
import {LoanDetailsTableLoanOfficer} from "./LoanDetailsTableLoanOfficer";
import {SubmitButton} from "../../../../input/component/submit_button/SubmitButton";
import {LoanDetailsTableBody} from "./loadn-details-table-body/LoanDetailsTableBody";
import {LoanDetailsTablePaymentAmount} from "./LoanDetailsTablePaymentAmount";

interface Props {
    loan: LoanData,
    proceedToPayment: () => any;
}

export class LoanDetailsTable extends React.Component<Props> {

    render() {
        let loan = this.props.loan;
        return <>
            <LoanDetailsTableBody>
                <LoanDetailsTableFirstName firstName={loan.borrower.firstName}/>

                <LoanDetailsTableLastName lastName={loan.borrower.lastName}/>

                <LoanDetailsTableLoanNumber loanNumber={loan.loanNumber}/>

                <LoanDetailsTableLoanAmount loanAmount={loan.loanAmount}/>

                <LoanDetailsTableFee fee={loan.fee}/>
                <LoanDetailsTablePaymentAmount paymentAmount={loan.paymentAmount}/>

                <LoanDetailsTablePaymentDue paymentDue={loan.paymentDue}/>

                <LoanDetailsTableLoanOfficer loanOfficer={loan.loanOfficer}/>

                <LoanDetailsTableProperty property={loan.property}/>
            </LoanDetailsTableBody>
            <SubmitButton onClick={this.props.proceedToPayment}>
                Confirm and Go To Payment
            </SubmitButton>

            <LoanDetailsCaption support={loan.support} type={loan.type}/>
        </>
    };

}