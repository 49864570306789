import React from "react";

export class LoanDetailsTableThread extends React.Component {
    render() {
        return <>
            <thead>
            <tr>
                <th colSpan={2}>Review Your Loan Information</th>
            </tr>
            </thead>
        </>;
    }
}