import React from "react";
import  './../PrePayment.scss'
import {ThemeProvider} from "@material-ui/core";
import {PrePaymentThemeHolder} from "../theme/PrePaymentThemeHolder";
import {PrePaymentWrapperHeader} from "./PrePaymentWrapperHeader";
import {PrePaymentWrapperFooter} from "./PrePaymentWrapperFooter";
import {LoanData} from "../../../../loan-details/model/LoanData";
import {PrePaymentWrapperIcons} from "./PrePaymentWrapperIcons";
import {toPublicUrl} from "src/modules/util/UrlUtil";

interface Props {
    loan: LoanData;
}

export class PrePaymentCreditCardWrapper extends React.Component<Props> {

    private static readonly theme = PrePaymentThemeHolder.THEME;

    render() {
        return <>
            <div id="payment_page">
                <PrePaymentWrapperHeader>
                    Online payments are powered by Stripe and guaranteed secure.
                </PrePaymentWrapperHeader>
                <PrePaymentWrapperIcons>
                    <img src={toPublicUrl("/images/icons/bank-logo-discover.svg")} alt={"DISCOVER"}/>
                    <img src={toPublicUrl("/images/icons/bank-logo-mastercard.svg")} alt={"MASTERCARD"}/>
                    <img src={toPublicUrl("/images/icons/bank-logo-visa.svg")} alt={"VISA"}/>
                </PrePaymentWrapperIcons>
                <div id="payment_page_form_wrapper">
                    <ThemeProvider theme={PrePaymentCreditCardWrapper.theme}>
                        {this.props.children}
                    </ThemeProvider>
                </div>
                <PrePaymentWrapperFooter loan={this.props.loan}/>
            </div>
        </>;
    }

}