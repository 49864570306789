import {HttpService} from "../../common/http/service/HttpService";
import {PlaidLinkTokenRequest} from "../module/PlaidLinkTokenRequest";

export class PlaidService {

    constructor(private httpService: HttpService) {
    }

    sendPaymentRequest = (publicToken: string,
                          pickedAccountId: string,
                          agreedToTerms: boolean): Promise<any> => {
        return this.httpService.fetch({
            url: 'stripe/process_payment/ach',
            method: 'POST',
            data: this.getRequest(agreedToTerms, publicToken, pickedAccountId)
        }).catch(e => {
            console.log(e);
        });

    };

    private getRequest = (agreedToTerms: boolean, publicToken: string, accountId: any): PlaidLinkTokenRequest => {
        return {
            agreedToTerms: agreedToTerms,
            plaidLinkTokenOptions: {
                publicToken: publicToken,
                accountId: accountId
            }
        };
    };

}