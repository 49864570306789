import React from "react";
import  './LoanDetailsCaption.scss'
import {CallForHelpLink} from "../../../app/tileable/instructions/contats/CallForHelpLink";
import {EmailForHelpLink} from "../../../app/tileable/instructions/contats/EmailForHelpLink";
import {ContactInfo} from "../../loan-details/model/ContactInfo";
import {LoanType} from "../../loan-details/model/LoanType";
import {LoanTypeUtil} from "../../../util/LoanTypeUtil";

interface Props {
    support: ContactInfo,
    type: LoanType
}

export class LoanDetailsCaption extends React.Component<Props> {

    render() {
        return (
            <>
                <p id="help-links">Have questions? <CallForHelpLink
                    support={this.props.support}>Call</CallForHelpLink>
                    &nbsp;or&nbsp;
                    <EmailForHelpLink support={this.props.support}>email</EmailForHelpLink> {this.getSupportAgent()} for
                    help!
                </p>
            </>
        );
    }

    private getSupportAgent = () => LoanTypeUtil.supportAgent(this.props.type);
}