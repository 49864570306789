import React from "react";
import {Instructions} from "./instructions/component/Instructions";
import {Body} from "./body/component/Body";
import {SecurityNote} from "../security-note/SecurityNote";
import {LoadableComponent} from "../loadable/LoadableComponent";

interface Props {
    instructions: any,
    loaded: boolean,
    keepChildren: boolean
}

export abstract class TileableComponent extends React.Component<Props> {

    public static defaultProps: Partial<Props> = {
        loaded: true,
        keepChildren: false
    }

    render() {
        return <>
            <Instructions>
                {/*apply loader at the bottom of instructions if on mobile*/}
                <LoadableComponent
                    loader={'mobile'}
                    loaded={this.props.loaded}
                    keepChildren={true}>
                    {this.props.instructions}
                </LoadableComponent>
            </Instructions>

            <Body>
                {/*apply loader over the grayedout contents if on desktop*/}
                <LoadableComponent
                    loader={'desktop'}
                    loaded={this.props.loaded}
                    keepChildren={this.props.keepChildren}>
                    {this.props.children}
                    <SecurityNote/>
                </LoadableComponent>
            </Body>
        </>
    }



}