import React from "react";
import  './SubmitButton.scss'

export class SubmitButton extends React.Component<React.ButtonHTMLAttributes<HTMLButtonElement>> {

    render() {
        return (
            <button className="submit_button" {...this.props}>
                {this.props.children}
            </button>
        );
    }

}