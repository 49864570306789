import React from "react";
import  "../../../../../app/tileable/instructions/component/Instructions.scss";

export class PostPayInstructions extends React.Component<{ firstName: string; }> {

    render() {
        return (
            <h1>Thanks, <br/>
                <span className="white-text">{this.props.firstName}!</span>
            </h1>
        );
    }

}