import React, {Component} from "react";
import {Property} from "../../model/Property";
import  "../LoanDetails.scss";
import {OptionalTableCell} from "../../../shared/component/OptionalTableCell";
import {AddressUtil} from "../../../../util/AddressUtil";

export class LoanDetailsTableProperty extends Component<{ property: Property }> {
    render() {
        return <>
            <OptionalTableCell title='Property'>
                <span className="single_string">
                    {AddressUtil.toString(this.props.property)}
                </span>
            </OptionalTableCell>
        </>
    }
}