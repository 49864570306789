import {injectable} from "tsyringe";
import {HttpService} from "../../common/http/service/HttpService";
import {PaymentStatus} from "../../flow/payment/service/PaymentService";
import {HttpError} from "../../common/http/model/HttpError";

export class StripePingService {

    constructor(private httpService: HttpService) {
    }

    public delayedPing(): Promise<null> {
        return this.httpService.fetchAs({
            url: '/stripe/initiate_delayed_ping',
            method: 'GET'
        })
    }

    public ping(): Promise<PaymentStatus | null> {
        return this.httpService.fetchAs<PaymentStatus | null>({
            url: '/stripe/ping',
            method: 'GET'
        }).catch(err => {
            if (err instanceof HttpError && err.code === 429) {
                return null;
            } else {
                throw err;
            }
        })
    }

}