import React, {Component} from "react";
import {PostPaymentItem} from "../item/PostPaymentItem";

export class PostPaymentHeader extends Component {
    render() {
        return <PostPaymentItem>
                    <span>
                        <h3>Huzzah! Your payment is complete!</h3>
                    </span>
        </PostPaymentItem>;
    }
}