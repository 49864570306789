import React from "react";
import  "./LoaderComponent.scss";
import {LoaderComponentImage} from "./LoaderComponentImage";
import {LoaderComponentBackground} from "./LoaderComponentBackground";

export class LoaderComponentDesktop extends React.Component {

    render() {
        return (
            <LoaderComponentBackground>
                <div className="loader_circle">
                    <LoaderComponentImage/>
                </div>
            </LoaderComponentBackground>
        );
    }

}