import React from "react";
import  "./LoaderComponent.scss";
import {LinearProgress} from "@material-ui/core";
import {LoaderComponentBackground} from "./LoaderComponentBackground";

export class LoaderComponentMobile extends React.Component {
    render() {
        return (
            <LoaderComponentBackground>
                <div className="loader_line">
                    <LinearProgress variant="indeterminate"/>
                </div>
            </LoaderComponentBackground>
        );
    }
}