import React from "react";
import {ContactInfo} from "../../../../flow/loan-details/model/ContactInfo";
import {BrowserServiceSingleton} from "src/Context";

interface Props {
    support: ContactInfo
}

export class CallForHelpLink extends React.Component<Props> {

    private readonly browserService = BrowserServiceSingleton;

    private callTo = (): string => {
        let phone = this.props.support.workingPhone;
        return this.browserService.callToLink(phone);
    }

    render() {
        return <a href={this.callTo()}>{this.props.children}</a>
    }

}