import {LoanData} from "../flow/loan-details/model/LoanData";
import {LoanType} from "../flow/loan-details/model/LoanType";

export class LoanTypeUtil {

    static supportAgentOf(loan: LoanData): string {
        let type = loan.type;
        return this.supportAgent(type);
    }

    static supportAgent(type: LoanType): string {
        switch (type) {
            case LoanType.APPRAISAL_FEE_LOAN:
                return 'your loan officer'
            case LoanType.INTEREST_LOAN:
                return 'our support team'
            case LoanType.UNKNOWN:
                return 'our support team'
            default:
                return ''
        }
    }

}