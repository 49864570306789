import React from "react";
import "../../PrePayment.scss";
import {container} from "tsyringe";
import {StripeCardService} from "../../../../service/StripeCardService";
import {StripeService} from "../../../../../../stripe/service/StripeService";
import {StripeCardElement, StripeCardElementChangeEvent, StripeElements} from "@stripe/stripe-js";
import {Box} from "@material-ui/core";
import {SnackbarService} from "../../../../../../snackbar/service/SnackbarService";
import {EventTypeHolder, EventUtil} from "../../../../../../util/EventUtil";
import {SnackbarServiceSingleton, StripeCardServiceSingleton, StripeServiceSingleton} from "src/Context";

interface Props {
    onCardValidityChanged: (cardValidity: boolean) => any,
}

interface State {
    errorMessage: string;
}

export class PrePaymentCardElement extends React.Component<Props, State> {

    private readonly stripeService = StripeServiceSingleton;
    private readonly stripeCardService = StripeCardServiceSingleton;
    private readonly snackbarService = SnackbarServiceSingleton;
    private cardElement!: StripeCardElement;

    constructor(props: Props) {
        super(props);
        this.state = {
            errorMessage: ''
        };
        this.handleErrorEvent();
        this.initCardElement();
    }

    private initCardElement = () => {
        try {
            this.stripeService.elementsPromise()
                .then(elements => this.mountCardElement(elements))
                .then(() => EventUtil.fireEvent(EventTypeHolder.stripeCardMounted));
        } catch (error) {
            if (typeof error == 'string') {
                this.snackbarService.error(error);
            } else if (error instanceof Error) {
                let message = error.message;
                this.snackbarService.error(message);
            } else {
                console.log(error);
            }
        }
    };

    private handleErrorEvent = () => {
        EventUtil.onCustomEvent<string>(EventTypeHolder.paymentFailure, event => {
            let errorMessage = event.detail;
            this.setErrorMessage(errorMessage);
        });
    };

    private mountCardElement = (elements: StripeElements) => {
        this.destroyPreviousCard(elements);
        this.cardElement = this.stripeCardService.createCard(elements, this.handleCardChange);
        this.cardElement.mount('#card-element');
    }

    private destroyPreviousCard = (elements: StripeElements) => {
        // @ts-ignore
        let prevCard = elements.getElement('card');
        prevCard?.destroy();
    };

    private handleCardChange = (event: StripeCardElementChangeEvent) => {
        let error = event.error;
        let validity = error == null;
        if (error) {
            this.setError(error);
        } else {
            this.removeErrorMessage();
        }
        this.props.onCardValidityChanged(validity);
    };

    private setError = (error: { type: "validation_error"; code: string; message: string }) => {
        let message = error.message;
        this.setErrorMessage(message);
    };

    private setErrorMessage = (message: string) => {
        this.setState({
            errorMessage: message
        })
    };

    private removeErrorMessage = () => {
        this.setState({
            errorMessage: ''
        })
    };

    getCardElement = (): StripeCardElement => {
        return this.cardElement;
    }

    render() {
        return (
            <div className="field card_wrapper">
                <div id="card-element" className="card_element"/>
                {/*todo move it ti separate component out of this div*/}
                <Box color={'red'}>
                    <label className="error_message"
                           color={'error'}> {this.state.errorMessage}</label>
                </Box>
            </div>
        );
    }

    componentWillUnmount() {
        this.cardElement?.unmount();
    }

}
