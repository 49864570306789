import {EventUtil} from "./EventUtil";

export class PromiseUtil {

    public static of = <T>(item: T): Promise<T> => {
        return new Promise<T>(resolve => resolve(item));
    }

    public static onEvent = <T>(eventName: string) => {
        return new Promise<T>(resolve => {
            let callback = (event: CustomEvent<T>) => {
                let item = event.detail;
                resolve(item);
            }
            EventUtil.target.addEventListener(eventName, callback as EventListener);
        })
    }

    public static wait = (ms: number) => {
        return new Promise(resolve => setTimeout(() => resolve(), ms));
    }

}