import React from "react";
import  "./../PrePayment.scss";
import Tooltip from "@material-ui/core/Tooltip";

export class PrePaymentFeeTooltipCreditCard extends React.Component {

    render() {
        return <div className="tool-tip">
            <Tooltip title={this.getTitle()}>
                <div className="tip-button">
                    ?
                </div>
            </Tooltip>
        </div>;
    }

    private getTitle = () => <p>
        Fee you pay to an independent licensed real estate appraiser to determine the value of the property.
    </p>;
}