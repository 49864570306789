import {HttpService} from "../../../common/http/service/HttpService";
import {BrowserService} from "../../../common/browser/BrowserService";
import {PaymentResult} from "../../../stripe/model/PaymentResult";
import {PaymentState} from "../model/PaymentState";
import {PaymentInfo} from "../model/PaymentInfo";

export interface PaymentStatus {
    state: PaymentState,
    result: PaymentResult
}



export class PaymentService {

    constructor(private httpService: HttpService,
                private browserService: BrowserService) {
    }

    public fetchStatus = (): Promise<PaymentInfo> => {
        return this.httpService.fetchAs<PaymentInfo>({
            url: '/stripe/payment_info',
            method: 'GET'
        });
    }

}